<script src="../store/modules/linkBuilderGeneric.js"></script>
<template>
  <div class="mb-3">
    <div v-if="!hideDragDrop" class="fileInput text-center">
      <!-- <div
        class="fileInput-new thumbnail"
      >
      </div> -->
      <!-- <div> -->
      <!-- <span class="fileInput-new">{{
            fileExists ? changeText : selectText
          }}</span> -->
      <div class="fileInput__text-wrapper">
        <i class="fileInput__icon-size fa-solid fa-cloud-arrow-up"></i>
        <span class="color-light"
          >Drag & drop or <label>click to choose file</label></span
        >
        <span>Maximum size: 10 MB</span>
        <span>Supported format: {{ fileType }} </span>
      </div>
      <input type="hidden" value="" name="" />
      <input
        :accept="fileType"
        type="file"
        name="..."
        class="fileInput__input"
        :multiple="multiple"
        aria-invalid="false"
        @change="onFileChange"
      />
      <!-- </div> -->
    </div>

    <div v-if="fileExists">
      <div v-if="!multiple" class="fileInput__list-item">
        <i class="fa-solid fa-file"></i>
        <div class="fileInput__list-item--text-wrapper">
          <p v-show="fileList">{{ fileList?.name }}</p>
          <span v-show="fileList.size">{{ formatSize(fileList.size) }}</span>
        </div>
        <i @click="deleteFile" class="fa-solid fa-trash-xmark"></i>
      </div>
      <div v-else>
        <div v-for="(file, index) in fileList" :key="index" class="fileInput__list-item">
          <i class="fa-solid fa-file"></i>
          <div class="fileInput__list-item--text-wrapper">
            <p v-show="file">{{ file?.name }}</p>
            <span v-show="file">{{ formatSize(file.size) }}</span>
          </div>
          <i @click="deleteFile(index)" class="fa-solid fa-trash-xmark"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FileUpload",
  props: {
    // selectText: {
    //   type: String,
    //   default: 'Select image'
    // },
    // changeText: {
    //   type: String,
    //   default: 'Change'
    // },
    // removeText: {
    //   type: String,
    //   default: 'Remove'
    // }
    multiple: {
      type: Boolean,
      default: false,
    },
    fileType: {
      type: String,
    },
    hideDragDrop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileList: null,
      formattedFileSize: null,
    };
  },
  computed: {
    fileExists() {
      return this.fileList !== null;
    },
  },
  methods: {
    removeFile() {
      this.imagePreview = null;
      this.$emit("change", null);
    },
    onFileChange(file) {
      if (this.multiple) {
        this.fileList = [];
        for(let i = 0; i < file.target.files.length; i++) {
          this.fileList.push(file.target.files[i]);
        }
        this.$emit("addFile", this.fileList);
      } else {
        this.fileList = file.target.files[0];
        this.$emit("addFile", file.target.files[0]);
      }
    },

    deleteFile(index = null) {
      if(index !== null) {
        this.fileList.splice(index, 1);
        this.$emit("addFile", this.fileList);
      } else {
        this.fileList = null;
        this.$emit("deleteFile", this.fileList);
      }
    },

    formatSize(bytes) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    },
  },
};
</script>
