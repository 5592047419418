var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.dashboard'),
          icon: 'fa-light fa-chart-tree-map',
          path: '/dashboard',
        }}}),_c('hr',{staticClass:"hr-sidebar mb-3"}),(_vm.hasCustomTools)?_c('sidebar-item',{attrs:{"link":{
          name: 'Premium Tools',
          isSvg: true,
          srcImg: '/img/icons/custom_icon.svg',
          path: '/tools?macrocategory=custom_tools',
        }}}):_vm._e(),(!_vm.isEntirePlatformCustom && _vm.hasTrendsTools)?_c('sidebar-item',{attrs:{"link":{
          name: 'Trends',
          icon: 'fa-light fa-arrow-trend-up',
          path: '/tools?macrocategory=trends',
        }}}):_vm._e(),(!_vm.isEntirePlatformCustom && _vm.hasSeoTools)?_c('sidebar-item',{attrs:{"link":{
          name: 'Seo',
          icon: 'fa-light fa-bullseye-arrow',
          path: '/tools?macrocategory=seo',
        }}}):_vm._e(),(!_vm.isEntirePlatformCustom && _vm.hasAiChatTools)?_c('sidebar-item',{attrs:{"link":{
          name: 'Ai Chat',
          icon: 'fa-light fa-message-bot',
          path: '/tools?macrocategory=ai_chat',
        }}}):_vm._e(),(!_vm.isEntirePlatformCustom && _vm.hasAiWriterTools)?_c('sidebar-item',{attrs:{"link":{
          name: 'AI Writer',
          icon: 'fa-light fa-pen-to-square',
          path: '/tools?macrocategory=ai_writer',
        }}}):_vm._e(),(!_vm.isEntirePlatformCustom && _vm.hasAiArtTools)?_c('sidebar-item',{attrs:{"link":{
          name: 'AI Art',
          icon: 'fa-light fa-camera-viewfinder',
          path: _vm.aiArtUrl,
          externalLink: true,
        }}}):_vm._e(),(!_vm.isEntirePlatformCustom && _vm.hasAudioTextTools)?_c('sidebar-item',{attrs:{"link":{
          name: `Audio ⇆ Text`,
          icon: 'fa-regular fa-file-audio',
          path: '/tools?macrocategory=audio_text',
        }}}):_vm._e(),(!_vm.isEntirePlatformCustom && _vm.hasAiTranslationTools)?_c('sidebar-item',{attrs:{"link":{
          name: 'AI Translation',
          icon: 'fa-solid fa-globe',
          path: '/tools?macrocategory=ai_translation',
        }}}):_vm._e(),_c('hr',{staticClass:"hr-sidebar mb-3"}),(!_vm.userExtraInfo?.hide_generic_task_assignment ?? false)?_c('sidebar-item',{attrs:{"link":{
          name: 'Task assignment',
          icon: 'fa-solid fa-list-check',
          path: '/task-assignment',
        }}}):_vm._e(),(_vm.hasAudienceManagerTool)?_c('sidebar-item',{attrs:{"link":{
          name: 'Audience Campaigns Manager',
          icon: 'fa-light fa-rectangle-ad',
          path: '/audience-campaigns/manager-dashboard',
        }}}):_vm._e(),(_vm.hasAudienceClientTool)?_c('sidebar-item',{attrs:{"link":{
          name: 'Audience Campaigns',
          icon: 'fa-light fa-rectangle-ad',
          path: '/audience-campaigns/client-dashboard',
        }}}):_vm._e(),(_vm.hasTranslationManagerTool)?_c('sidebar-item',{attrs:{"link":{
          name: 'Translations Manager',
          icon: 'fa-light fa-language',
          path: '/translation-manager/assignee-dashboard',
        }}}):_vm._e(),(_vm.hasTranslationClientTool)?_c('sidebar-item',{attrs:{"link":{
          name: 'Translations',
          icon: 'fa-light fa-language',
          path: '/translation-manager/client-dashboard',
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Documents',
          icon: 'fa-light fa-folder-bookmark',
          path: '/orders',
        }}})],1)],2),_c('sidebar-share',{attrs:{"background-color":_vm.sidebarBackground},on:{"update:backgroundColor":function($event){_vm.sidebarBackground=$event},"update:background-color":function($event){_vm.sidebarBackground=$event}}}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[(_vm.showCompanyNav)?_c('dashboard-company-navbar'):_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }