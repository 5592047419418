<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: $t('sidebar.dashboard'),
            icon: 'fa-light fa-chart-tree-map',
            path: '/dashboard',
          }"
        />

        <hr class="hr-sidebar mb-3" />

        <sidebar-item
          v-if="hasCustomTools"
          :link="{
            name: 'Premium Tools',
            isSvg: true,
            srcImg: '/img/icons/custom_icon.svg',
            path: '/tools?macrocategory=custom_tools',
          }"
        />

        <sidebar-item
          v-if="!isEntirePlatformCustom && hasTrendsTools"
          :link="{
            name: 'Trends',
            icon: 'fa-light fa-arrow-trend-up',
            path: '/tools?macrocategory=trends',
          }"
        />

        <sidebar-item
          v-if="!isEntirePlatformCustom && hasSeoTools"
          :link="{
            name: 'Seo',
            icon: 'fa-light fa-bullseye-arrow',
            path: '/tools?macrocategory=seo',
          }"
        />

        <sidebar-item
          v-if="!isEntirePlatformCustom && hasAiChatTools"
          :link="{
            name: 'Ai Chat',
            icon: 'fa-light fa-message-bot',
            path: '/tools?macrocategory=ai_chat',
          }"
        />

        <sidebar-item
          v-if="!isEntirePlatformCustom && hasAiWriterTools"
          :link="{
            name: 'AI Writer',
            icon: 'fa-light fa-pen-to-square',
            path: '/tools?macrocategory=ai_writer',
          }"
        />

        <sidebar-item
          v-if="!isEntirePlatformCustom && hasAiArtTools"
          :link="{
            name: 'AI Art',
            icon: 'fa-light fa-camera-viewfinder',
            path: aiArtUrl,
            externalLink: true,
          }"
        />

        <sidebar-item
          v-if="!isEntirePlatformCustom && hasAudioTextTools"
          :link="{
            name: `Audio ⇆ Text`,
            icon: 'fa-regular fa-file-audio',
            path: '/tools?macrocategory=audio_text',
          }"
        />

        <sidebar-item
          v-if="!isEntirePlatformCustom && hasAiTranslationTools"
          :link="{
            name: 'AI Translation',
            icon: 'fa-solid fa-globe',
            path: '/tools?macrocategory=ai_translation',
          }"
        />

        <hr class="hr-sidebar mb-3" />

        <sidebar-item
          v-if="!userExtraInfo?.hide_generic_task_assignment ?? false"
          :link="{
            name: 'Task assignment',
            icon: 'fa-solid fa-list-check',
            path: '/task-assignment',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="hasAudienceManagerTool"
          :link="{
            name: 'Audience Campaigns Manager',
            icon: 'fa-light fa-rectangle-ad',
            path: '/audience-campaigns/manager-dashboard',
          }"
        ></sidebar-item>

        <sidebar-item
          v-if="hasAudienceClientTool"
          :link="{
            name: 'Audience Campaigns',
            icon: 'fa-light fa-rectangle-ad',
            path: '/audience-campaigns/client-dashboard',
          }"
        ></sidebar-item>

        <sidebar-item
          v-if="hasTranslationManagerTool"
          :link="{
            name: 'Translations Manager',
            icon: 'fa-light fa-language',
            path: '/translation-manager/assignee-dashboard',
          }"
        ></sidebar-item>

        <sidebar-item
          v-if="hasTranslationClientTool"
          :link="{
            name: 'Translations',
            icon: 'fa-light fa-language',
            path: '/translation-manager/client-dashboard',
          }"
        ></sidebar-item>

        <sidebar-item
          :link="{
            name: 'Documents',
            icon: 'fa-light fa-folder-bookmark',
            path: '/orders',
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>

    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <sidebar-share :background-color.sync="sidebarBackground"></sidebar-share>
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-company-navbar v-if="showCompanyNav"></dashboard-company-navbar>
      <dashboard-navbar v-else></dashboard-navbar>
      <router-view name="header"></router-view>

      <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import SidebarShare from "./SidebarSharePlugin";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import DashboardCompanyNavbar from "./DashboardCompanyNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import SidebarFixedToggleButton from "./SidebarFixedToggleButton.vue";
import { ZoomCenterTransition } from "vue2-transitions";
import { getDarkMode } from "../../services/localService";
import { mapGetters } from "vuex";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    ZoomCenterTransition,
    SidebarShare,
    DashboardCompanyNavbar,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  created() {
    console.log("sono in app");
    this.fetchUserInfoForCustomTheme();
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      userExtraInfo: "getUserExtraInfo",
    }),
    hasTrendsTools() {
      return this.$store.getters.getTools.some(
        (t) => t.macrocategory === "trends" && !t.is_custom
      );
    },
    hasSeoTools() {
      return this.$store.getters.getTools.some(
        (t) => t.macrocategory === "seo" && !t.is_custom
      );
    },
    hasAiChatTools() {
      return this.$store.getters.getTools.some(
        (t) => t.macrocategory === "ai_chat" && !t.is_custom
      );
    },
    hasAiWriterTools() {
      return this.$store.getters.getTools.some(
        (t) => t.macrocategory === "ai_writer" && !t.is_custom
      );
    },
    hasAiArtTools() {
      return this.$store.getters.getTools.some(
        (t) => t.macrocategory === "ai_art" && !t.is_custom
      );
    },
    hasAudioTextTools() {
      return this.$store.getters.getTools.some(
        (t) => t.macrocategory === "audio_text" && !t.is_custom
      );
    },
    hasAiTranslationTools() {
      return this.$store.getters.getTools.some(
        (t) => t.macrocategory === "ai_translation" && !t.is_custom
      );
    },
    hasCustomTools() {
      return this.$store.getters.getTools.some((t) => t.is_custom);
    },
    hasAudienceManagerTool() {
      return this.$store.getters.getUser.is_admin ?? false;
    },
    hasAudienceClientTool() {
      return this.$store.getters.getUser.is_admin ?? false;
    },
    hasTranslationManagerTool() {
      return this.$store.getters.getUser.is_admin ?? false;
    },
    hasTranslationClientTool() {
      return this.$store.getters.getUser.is_admin ?? false;
    },
    isEntirePlatformCustom() {
      return this.hasCustomTools && this.user.extra_info?.custom_tool_name;
    },
    userHasCompanyJumbo() {
      return this.user.extra_info?.company_jumbo_logo ? true : false;
    },
    hideFooter() {
      return this.tool?.macrocategory != "ai_chat" || !this.$route.meta.hideFooter;
    },
    tool() {
      const _this = this;
      const slug = this.$route.params.slug;
      return this.$store.getters.getTools.find((tool) => tool.slug === slug);
    },

    currentRouteName() {
      return this.$route.name;
    },

    isDashboardCurrentRoute() {
      return this.currentRouteName === "Dashboard";
    },

    showCompanyNav() {
      return this.isDashboardCurrentRoute && this.userHasCompanyJumbo;
    },

    aiArtUrl() {
      return process.env.VUE_APP_AI_ART_BASE_URL;
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("sidebar");
        initScrollbar("main-panel");
        initScrollbar("sidebar-wrapper");

        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    },
    initDarkMode() {
      let docClasses = document.body.classList;
      if (getDarkMode()) {
        docClasses.remove("white-content");
      } else {
        docClasses.add("white-content");
      }
    },
    async fetchUserInfoForCustomTheme() {
      await this.$store.dispatch("fetchUserInfo");
      if (this.userExtraInfo?.company_custom_theme) {
        const theme = await import(
          `src/util/themes/${this.userExtraInfo?.company_custom_theme.toLowerCase()}CustomTheme.js`
        );
        let customTheme = {
          customBackground: this.userExtraInfo?.custom_color_background,
          customPrimary: this.userExtraInfo?.custom_color_primary,
          customSecondary: this.userExtraInfo?.custom_color_secondary,
        };
        theme.CustomTheme(customTheme);
      }
    },
    initializeLayout() {
      if (!this.$rtl.isRTL) {
        // Just make sure rtl css is off when we are not on rtl
        this.$rtl.disableRTL();
      }
    },
  },
  mounted() {
    this.initScrollbar();
    this.initDarkMode();
    this.initializeLayout();
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
